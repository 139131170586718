/* eslint-disable no-template-curly-in-string */

export const config = () => {

    return ([
            {
                "id": "mysql_problem1",
                "title": "Connecting to MySQL",
                "technology": "MySQL",
                "markdown": "### Problem\nConnecting to MySQL\n\n### Solution\nUse `mysqli` or `PDO` to connect to a MySQL database.\n\n```php\n$mysqli = new mysqli('localhost', 'username', 'password', 'database');\nif ($mysqli->connect_error) {\n  die('Connect Error (' . $mysqli->connect_errno . ') ' . $mysqli->connect_error);\n}\n```"
            },
            {
                "id": "mysql_problem2",
                "title": "Selecting Data",
                "technology": "MySQL",
                "markdown": "### Problem\nSelecting Data\n\n### Solution\nUse `SELECT` statement to retrieve data from a table.\n\n```sql\nSELECT * FROM users;\n```"
            },
            {
                "id": "mysql_problem3",
                "title": "Inserting Data",
                "technology": "MySQL",
                "markdown": "### Problem\nInserting Data\n\n### Solution\nUse `INSERT INTO` statement to add new records to a table.\n\n```sql\nINSERT INTO users (name, email) VALUES ('John', 'john@example.com');\n```"
            },
            {
                "id": "mysql_problem4",
                "title": "Updating Data",
                "technology": "MySQL",
                "markdown": "### Problem\nUpdating Data\n\n### Solution\nUse `UPDATE` statement to modify existing records in a table.\n\n```sql\nUPDATE users SET email = 'john.doe@example.com' WHERE name = 'John';\n```"
            },
            {
                "id": "mysql_problem5",
                "title": "Deleting Data",
                "technology": "MySQL",
                "markdown": "### Problem\nDeleting Data\n\n### Solution\nUse `DELETE` statement to remove records from a table.\n\n```sql\nDELETE FROM users WHERE name = 'John';\n```"
            },
            {
                "id": "mysql_problem6",
                "title": "Preventing SQL Injection",
                "technology": "MySQL",
                "markdown": "### Problem\nPreventing SQL Injection\n\n### Solution\nUse prepared statements to prevent SQL injection.\n\n```php\n$stmt = $mysqli->prepare('SELECT * FROM users WHERE email = ?');\n$stmt->bind_param('s', $email);\n$stmt->execute();\n```"
            },
            {
                "id": "mysql_problem7",
                "title": "Creating a Table",
                "technology": "MySQL",
                "markdown": "### Problem\nCreating a Table\n\n### Solution\nUse `CREATE TABLE` statement to create a new table.\n\n```sql\nCREATE TABLE users (\n  id INT AUTO_INCREMENT PRIMARY KEY,\n  name VARCHAR(100),\n  email VARCHAR(100)\n);\n```"
            },
            {
                "id": "mysql_problem8",
                "title": "Dropping a Table",
                "technology": "MySQL",
                "markdown": "### Problem\nDropping a Table\n\n### Solution\nUse `DROP TABLE` statement to delete a table.\n\n```sql\nDROP TABLE users;\n```"
            },
            {
                "id": "mysql_problem9",
                "title": "Adding a Column",
                "technology": "MySQL",
                "markdown": "### Problem\nAdding a Column\n\n### Solution\nUse `ALTER TABLE` statement to add a new column to a table.\n\n```sql\nALTER TABLE users ADD age INT;\n```"
            },
            {
                "id": "mysql_problem10",
                "title": "Removing a Column",
                "technology": "MySQL",
                "markdown": "### Problem\nRemoving a Column\n\n### Solution\nUse `ALTER TABLE` statement to drop a column from a table.\n\n```sql\nALTER TABLE users DROP COLUMN age;\n```"
            },
            {
                "id": "mysql_problem11",
                "title": "Renaming a Table",
                "technology": "MySQL",
                "markdown": "### Problem\nRenaming a Table\n\n### Solution\nUse `RENAME TABLE` statement to rename a table.\n\n```sql\nRENAME TABLE users TO customers;\n```"
            },
            {
                "id": "mysql_problem12",
                "title": "Adding an Index",
                "technology": "MySQL",
                "markdown": "### Problem\nAdding an Index\n\n### Solution\nUse `CREATE INDEX` statement to add an index to a table.\n\n```sql\nCREATE INDEX idx_name ON users (name);\n```"
            },
            {
                "id": "mysql_problem13",
                "title": "Removing an Index",
                "technology": "MySQL",
                "markdown": "### Problem\nRemoving an Index\n\n### Solution\nUse `DROP INDEX` statement to remove an index from a table.\n\n```sql\nDROP INDEX idx_name ON users;\n```"
            },
            {
                "id": "mysql_problem14",
                "title": "Foreign Keys",
                "technology": "MySQL",
                "markdown": "### Problem\nForeign Keys\n\n### Solution\nUse `FOREIGN KEY` constraints to enforce referential integrity.\n\n```sql\nCREATE TABLE orders (\n  id INT AUTO_INCREMENT PRIMARY KEY,\n  user_id INT,\n  FOREIGN KEY (user_id) REFERENCES users(id)\n);\n```"
            },
            {
                "id": "mysql_problem15",
                "title": "Joining Tables",
                "technology": "MySQL",
                "markdown": "### Problem\nJoining Tables\n\n### Solution\nUse `JOIN` clauses to combine rows from two or more tables.\n\n```sql\nSELECT users.name, orders.id\nFROM users\nJOIN orders ON users.id = orders.user_id;\n```"
            },
            {
                "id": "mysql_problem16",
                "title": "Group By Clause",
                "technology": "MySQL",
                "markdown": "### Problem\nGroup By Clause\n\n### Solution\nUse `GROUP BY` clause to group rows that have the same values.\n\n```sql\nSELECT COUNT(*), country\nFROM users\nGROUP BY country;\n```"
            },
            {
                "id": "mysql_problem17",
                "title": "Order By Clause",
                "technology": "MySQL",
                "markdown": "### Problem\nOrder By Clause\n\n### Solution\nUse `ORDER BY` clause to sort the result set.\n\n```sql\nSELECT * FROM users ORDER BY name ASC;\n```"
            },
            {
                "id": "mysql_problem18",
                "title": "Limit Clause",
                "technology": "MySQL",
                "markdown": "### Problem\nLimit Clause\n\n### Solution\nUse `LIMIT` clause to specify the number of records to return.\n\n```sql\nSELECT * FROM users LIMIT 10;\n```"
            },
            {
                "id": "mysql_problem19",
                "title": "Aggregate Functions",
                "technology": "MySQL",
                "markdown": "### Problem\nAggregate Functions\n\n### Solution\nUse aggregate functions like `COUNT`, `SUM`, `AVG`, `MAX`, and `MIN`.\n\n```sql\nSELECT COUNT(*) FROM users;\n```"
            },
            {
                "id": "mysql_problem20",
                "title": "Subqueries",
                "technology": "MySQL",
                "markdown": "### Problem\nSubqueries\n\n### Solution\nUse subqueries to nest one query inside another.\n\n```sql\nSELECT * FROM users WHERE id IN (SELECT user_id FROM orders);\n```"
            },
            {
                "id": "mysql_problem21",
                "title": "Case Statements",
                "technology": "MySQL",
                "markdown": "### Problem\nCase Statements\n\n### Solution\nUse `CASE` statement to return different values based on conditions.\n\n```sql\nSELECT name,\n  CASE\n    WHEN age < 18 THEN 'Minor'\n    ELSE 'Adult'\n  END AS age_group\nFROM users;\n```"
            },
            {
                "id": "mysql_problem22",
                "title": "Handling NULL Values",
                "technology": "MySQL",
                "markdown": "### Problem\nHandling NULL Values\n\n### Solution\nUse `IS NULL` and `IS NOT NULL` to check for NULL values.\n\n```sql\nSELECT * FROM users WHERE email IS NULL;\n```"
            },
            {
                "id": "mysql_problem23",
                "title": "Full-Text Search",
                "technology": "MySQL",
                "markdown": "### Problem\nFull-Text Search\n\n### Solution\nUse full-text search to perform complex searches on text data.\n\n```sql\nSELECT * FROM articles WHERE MATCH (title, body) AGAINST ('database');\n```"
            },
            {
                "id": "mysql_problem24",
                "title": "Stored Procedures",
                "technology": "MySQL",
                "markdown": "### Problem\nStored Procedures\n\n### Solution\nUse stored procedures to encapsulate complex queries.\n\n```sql\nDELIMITER //\nCREATE PROCEDURE GetUsers()\nBEGIN\n  SELECT * FROM users;\nEND //\nDELIMITER ;\n```"
            },
            {
                "id": "mysql_problem25",
                "title": "Triggers",
                "technology": "MySQL",
                "markdown": "### Problem\nTriggers\n\n### Solution\nUse triggers to automatically perform actions in response to certain events.\n\n```sql\nCREATE TRIGGER before_insert_users\nBEFORE INSERT ON users\nFOR EACH ROW\nBEGIN\n  SET NEW.created_at = NOW();\nEND;\n```"
            }
        ]
    );
}