import {AppBar, Link, Toolbar} from "@mui/material";
import React from "react";

export const Header: React.FC = () => {

    return (
        <React.Fragment>
            <AppBar position="sticky">
                <Toolbar>
                    <Link sx={{height: '40px'}}href={'/'}><img
                        alt="A logo representing a cup of fresh Lemonade"
                        src={'/logo.png'} width={40} height={40}/></Link>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
