import React, {useEffect} from 'react';

import {
    Button,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';
import MarkdownRenderer from './MarkdownRenderer';


export const EntryList = (
    props: {
        data: any,
        label: string,
        id: string
    }
) => {
    const [isHidden, setIsHidden] = React.useState<boolean>(false);
    const [markdown, setMarkdown] = React.useState<any>('');
    const [data, setData] = React.useState<Array<any>>(props.data);
    const [collected, setCollected] = React.useState<boolean>(false);


    const showData = (value: string) => {
        setIsHidden(true);
        setMarkdown(value);
    }

    useEffect(() => {
        if (collected)
            return;

        fetch(`/.netlify/functions/data/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                type: props.id.toLowerCase()
            })
        }).then((result) => {
            return result.json();
        }).then((newData: Array<any>) => {
            setData([
                ...newData,
                ...data
            ]);

            setCollected(true);
        });
    }, [props.id, collected, data]);

    return (<React.Fragment>
        <Stack spacing={2}>
            <Typography variant="h6" component={'h2'} align="left">
                {props.label}
            </Typography>
            <TableContainer hidden={isHidden}>
                <Table>
                    <TableBody>
                        {data.map((row: any, index: number) => (
                            <TableRow key={index}>
                                <TableCell align={"left"}>
                                    <Button sx={{color: 'black'}}
                                            variant="text"
                                            onClick={() => showData(row.markdown)}>{row.title}</Button>
                                </TableCell>
                                <TableCell align="right">
                                    <Button
                                        onClick={() => showData(row.markdown)}
                                        variant="contained" disableElevation>View</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Container sx={{display: (isHidden) ? 'block' : 'none'}}>
                <Button onClick={() => setIsHidden(false)}
                        variant={'contained'}
                        color={'success'} disableElevation>Back</Button>
                <MarkdownRenderer>{markdown}</MarkdownRenderer>
            </Container>
        </Stack>
    </React.Fragment>);
}
