/* eslint-disable no-template-curly-in-string */

export const config = () => {

    return ([
            {
                "id": "react_netlify_problem1",
                "title": "Setting Up React with Netlify",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nSetting Up React with Netlify\n\n### Solution\nInitialize your React project and deploy it on Netlify.\n\n```bash\nnpx create-react-app my-app\ncd my-app\ngit init\ngit add .\ngit commit -m \"Initial commit\"\n\n# Create a new site on Netlify\nnetlify init\n\n# Deploy the site\nnetlify deploy --prod\n```\n"
            },
            {
                "id": "react_netlify_problem2",
                "title": "Environment Variables in Netlify",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nEnvironment Variables in Netlify\n\n### Solution\nSet environment variables in Netlify dashboard.\n\n1. Go to your site in Netlify dashboard.\n2. Click on 'Site settings'.\n3. Go to 'Build & deploy' > 'Environment' > 'Environment variables'.\n4. Add your environment variables and deploy your site.\n\nAccess these variables in your React app using `process.env.REACT_APP_VARIABLE_NAME`.\n"
            },
            {
                "id": "react_netlify_problem3",
                "title": "Handling 404 Pages",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nHandling 404 Pages\n\n### Solution\nCreate a `_redirects` file to handle 404 pages.\n\n```plaintext\n/*    /index.html   200\n```\nPlace this file in the `public` directory of your React project.\n"
            },
            {
                "id": "react_netlify_problem4",
                "title": "Continuous Deployment with Netlify",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nContinuous Deployment with Netlify\n\n### Solution\nConnect your GitHub repository to Netlify for continuous deployment.\n\n1. Go to your site in Netlify dashboard.\n2. Click on 'Site settings'.\n3. Go to 'Build & deploy' > 'Continuous Deployment'.\n4. Connect to GitHub and select your repository.\n5. Set the build command to `npm run build` and the publish directory to `build`.\n"
            },
            {
                "id": "react_netlify_problem5",
                "title": "Custom Domains in Netlify",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nCustom Domains in Netlify\n\n### Solution\nSet up a custom domain in Netlify.\n\n1. Go to your site in Netlify dashboard.\n2. Click on 'Domain management'.\n3. Add your custom domain and follow the instructions to update your DNS settings.\n"
            },
            {
                "id": "react_netlify_problem6",
                "title": "Form Handling in Netlify",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nForm Handling in Netlify\n\n### Solution\nUse Netlify forms to handle form submissions.\n\n```html\n<form name=\"contact\" method=\"POST\" data-netlify=\"true\">\n  <input type=\"hidden\" name=\"form-name\" value=\"contact\" />\n  <p>\n    <label>Your Name: <input type=\"text\" name=\"name\" /></label>   \n  </p>\n  <p>\n    <label>Your Email: <input type=\"email\" name=\"email\" /></label>\n  </p>\n  <p>\n    <label>Your Role: <select name=\"role[]\">\n      <option value=\"leader\">Leader</option>\n      <option value=\"follower\">Follower</option>\n    </select></label>\n  </p>\n  <p>\n    <button type=\"submit\">Send</button>\n  </p>\n</form>\n```\n"
            },
            {
                "id": "react_netlify_problem7",
                "title": "Debugging Build Failures",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nDebugging Build Failures\n\n### Solution\nCheck the build logs in Netlify dashboard to identify issues.\n\n1. Go to your site in Netlify dashboard.\n2. Click on 'Deploys'.\n3. Select the failed deploy and view the logs to identify the issue.\n"
            },
            {
                "id": "react_netlify_problem8",
                "title": "Using Netlify Functions with React",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nUsing Netlify Functions with React\n\n### Solution\nCreate serverless functions in Netlify and use them in your React app.\n\n1. Create a `netlify/functions` directory in your project.\n2. Add a function file, e.g., `hello.js`:\n\n```javascript\nexports.handler = async function(event, context) {\n  return {\n    statusCode: 200,\n    body: JSON.stringify({ message: \"Hello, world\" })\n  };\n};\n```\n\n3. Deploy your site to Netlify.\n4. Access the function in your React app:\n\n```javascript\nfetch('/.netlify/functions/hello')\n  .then(response => response.json())\n  .then(data => console.log(data));\n```\n"
            },
            {
                "id": "react_netlify_problem9",
                "title": "Optimizing React App for Netlify",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nOptimizing React App for Netlify\n\n### Solution\nUse code splitting and lazy loading to optimize your React app.\n\n```javascript\nimport React, { Suspense, lazy } from 'react';\n\nconst OtherComponent = lazy(() => import('./OtherComponent'));\n\nfunction App() {\n  return (\n    <div>\n      <Suspense fallback={<div>Loading...</div>}>\n        <OtherComponent />\n      </Suspense>\n    </div>\n  );\n}\n\nexport default App;\n```\n"
            },
            {
                "id": "react_netlify_problem10",
                "title": "Using Netlify Redirects with React Router",
                "technology": "ReactJS, Netlify",
                "markdown": "### Problem\nUsing Netlify Redirects with React Router\n\n### Solution\nConfigure redirects to support client-side routing in React Router.\n\n1. Create a `_redirects` file in the `public` directory:\n\n```plaintext\n/*    /index.html   200\n```\n\n2. Ensure your React Router setup includes `BrowserRouter`:\n\n```javascript\nimport { BrowserRouter as Router, Route, Switch } from 'react-router-dom';\n\nfunction App() {\n  return (\n    <Router>\n      <Switch>\n        <Route path=\"/home\" component={Home} />\n        <Route path=\"/about\" component={About} />\n      </Switch>\n    </Router>\n  );\n}\n\nexport default App;\n```\n"
            }
        ]
    );
}