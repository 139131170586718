/* eslint-disable no-template-curly-in-string */

export const config = () => {

    return ([
            {
                "id": "firebase_problem1",
                "title": "Setting Up Firebase",
                "technology": "Firebase",
                "markdown": "### Problem\nSetting Up Firebase\n\n### Solution\nInitialize Firebase in your project by including the Firebase SDK.\n\n```javascript\nimport { initializeApp } from 'firebase/app';\n\nconst firebaseConfig = {\n  apiKey: \"YOUR_API_KEY\",\n  authDomain: \"YOUR_AUTH_DOMAIN\",\n  projectId: \"YOUR_PROJECT_ID\",\n  storageBucket: \"YOUR_STORAGE_BUCKET\",\n  messagingSenderId: \"YOUR_MESSAGING_SENDER_ID\",\n  appId: \"YOUR_APP_ID\"\n};\n\nconst app = initializeApp(firebaseConfig);\n```"
            },
            {
                "id": "firebase_problem2",
                "title": "Authentication with Email and Password",
                "technology": "Firebase",
                "markdown": "### Problem\nAuthentication with Email and Password\n\n### Solution\nUse Firebase Authentication to allow users to sign in with email and password.\n\n```javascript\nimport { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';\n\nconst auth = getAuth();\n\ncreateUserWithEmailAndPassword(auth, email, password)\n  .then((userCredential) => {\n    const user = userCredential.user;\n  })\n  .catch((error) => {\n    const errorCode = error.code;\n    const errorMessage = error.message;\n  });\n\nsignInWithEmailAndPassword(auth, email, password)\n  .then((userCredential) => {\n    const user = userCredential.user;\n  })\n  .catch((error) => {\n    const errorCode = error.code;\n    const errorMessage = error.message;\n  });\n```"
            },
            {
                "id": "firebase_problem3",
                "title": "Firestore: Adding a Document",
                "technology": "Firebase",
                "markdown": "### Problem\nFirestore: Adding a Document\n\n### Solution\nUse Firestore to add a new document to a collection.\n\n```javascript\nimport { getFirestore, collection, addDoc } from 'firebase/firestore';\n\nconst db = getFirestore();\n\ntry {\n  const docRef = await addDoc(collection(db, 'users'), {\n    name: 'John Doe',\n    email: 'johndoe@example.com'\n  });\n  console.log('Document written with ID: ', docRef.id);\n} catch (e) {\n  console.error('Error adding document: ', e);\n}\n```"
            },
            {
                "id": "firebase_problem4",
                "title": "Firestore: Reading a Document",
                "technology": "Firebase",
                "markdown": "### Problem\nFirestore: Reading a Document\n\n### Solution\nUse Firestore to read a document from a collection.\n\n```javascript\nimport { getFirestore, doc, getDoc } from 'firebase/firestore';\n\nconst db = getFirestore();\nconst docRef = doc(db, 'users', 'user_id');\nconst docSnap = await getDoc(docRef);\n\nif (docSnap.exists()) {\n  console.log('Document data:', docSnap.data());\n} else {\n  console.log('No such document!');\n}\n```"
            },
            {
                "id": "firebase_problem5",
                "title": "Firestore: Updating a Document",
                "technology": "Firebase",
                "markdown": "### Problem\nFirestore: Updating a Document\n\n### Solution\nUse Firestore to update an existing document.\n\n```javascript\nimport { getFirestore, doc, updateDoc } from 'firebase/firestore';\n\nconst db = getFirestore();\nconst docRef = doc(db, 'users', 'user_id');\n\nawait updateDoc(docRef, {\n  email: 'newemail@example.com'\n});\n```"
            },
            {
                "id": "firebase_problem6",
                "title": "Firestore: Deleting a Document",
                "technology": "Firebase",
                "markdown": "### Problem\nFirestore: Deleting a Document\n\n### Solution\nUse Firestore to delete a document from a collection.\n\n```javascript\nimport { getFirestore, doc, deleteDoc } from 'firebase/firestore';\n\nconst db = getFirestore();\nawait deleteDoc(doc(db, 'users', 'user_id'));\n```"
            },
            {
                "id": "firebase_problem7",
                "title": "Firebase Storage: Uploading a File",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Storage: Uploading a File\n\n### Solution\nUse Firebase Storage to upload files.\n\n```javascript\nimport { getStorage, ref, uploadBytes } from 'firebase/storage';\n\nconst storage = getStorage();\nconst storageRef = ref(storage, 'some-child');\n\nconst file = ...; // use the Blob or File API\nuploadBytes(storageRef, file).then((snapshot) => {\n  console.log('Uploaded a blob or file!');\n});\n```"
            },
            {
                "id": "firebase_problem8",
                "title": "Firebase Storage: Downloading a File",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Storage: Downloading a File\n\n### Solution\nUse Firebase Storage to download files.\n\n```javascript\nimport { getStorage, ref, getDownloadURL } from 'firebase/storage';\n\nconst storage = getStorage();\nconst storageRef = ref(storage, 'some-child');\n\ngetDownloadURL(storageRef)\n  .then((url) => {\n    // `url` is the download URL for 'some-child'\n    console.log('File available at', url);\n  })\n  .catch((error) => {\n    console.error('Error downloading file:', error);\n  });\n```"
            },
            {
                "id": "firebase_problem9",
                "title": "Firebase Hosting: Deploying the App",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Hosting: Deploying the App\n\n### Solution\nUse Firebase CLI to deploy your app to Firebase Hosting.\n\n```bash\nfirebase login\nfirebase init\nfirebase deploy\n```"
            },
            {
                "id": "firebase_problem10",
                "title": "Firebase Realtime Database: Writing Data",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Realtime Database: Writing Data\n\n### Solution\nUse Firebase Realtime Database to write data to a specified reference.\n\n```javascript\nimport { getDatabase, ref, set } from 'firebase/database';\n\nconst db = getDatabase();\nset(ref(db, 'users/' + userId), {\n  username: name,\n  email: email,\n  profile_picture: imageUrl\n});\n```"
            },
            {
                "id": "firebase_problem11",
                "title": "Firebase Realtime Database: Reading Data",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Realtime Database: Reading Data\n\n### Solution\nUse Firebase Realtime Database to read data from a specified reference.\n\n```javascript\nimport { getDatabase, ref, child, get } from 'firebase/database';\n\nconst dbRef = ref(getDatabase());\nget(child(dbRef, 'users/' + userId)).then((snapshot) => {\n  if (snapshot.exists()) {\n    console.log(snapshot.val());\n  } else {\n    console.log('No data available');\n  }\n}).catch((error) => {\n  console.error(error);\n});\n```"
            },
            {
                "id": "firebase_problem12",
                "title": "Firebase Realtime Database: Updating Data",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Realtime Database: Updating Data\n\n### Solution\nUse Firebase Realtime Database to update data at a specified reference.\n\n```javascript\nimport { getDatabase, ref, update } from 'firebase/database';\n\nconst db = getDatabase();\nupdate(ref(db, 'users/' + userId), {\n  username: newName,\n  email: newEmail\n});\n```"
            },
            {
                "id": "firebase_problem13",
                "title": "Firebase Realtime Database: Deleting Data",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Realtime Database: Deleting Data\n\n### Solution\nUse Firebase Realtime Database to delete data at a specified reference.\n\n```javascript\nimport { getDatabase, ref, remove } from 'firebase/database';\n\nconst db = getDatabase();\nremove(ref(db, 'users/' + userId));\n```"
            },
            {
                "id": "firebase_problem14",
                "title": "Firebase Functions: Writing a Function",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Functions: Writing a Function\n\n### Solution\nUse Firebase Functions to write and deploy serverless functions.\n\n```javascript\nconst functions = require('firebase-functions');\n\nexports.helloWorld = functions.https.onRequest((request, response) => {\n  response.send('Hello from Firebase!');\n});\n```"
            },
            {
                "id": "firebase_problem15",
                "title": "Firebase Functions: Deploying a Function",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Functions: Deploying a Function\n\n### Solution\nUse Firebase CLI to deploy your functions.\n\n```bash\nfirebase deploy --only functions\n```"
            },
            {
                "id": "firebase_problem16",
                "title": "Firebase Functions: Debugging",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Functions: Debugging\n\n### Solution\nUse `firebase functions:log` to view logs and debug functions.\n\n```bash\nfirebase functions:log\n```"
            },
            {
                "id": "firebase_problem17",
                "title": "Firebase Authentication: Debugging",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Authentication: Debugging\n\n### Solution\nCheck the error code and message returned by Firebase Authentication for debugging.\n\n```javascript\nsignInWithEmailAndPassword(auth, email, password)\n  .catch((error) => {\n    const errorCode = error.code;\n    const errorMessage = error.message;\n    console.error('Error signing in:', errorCode, errorMessage);\n  });\n```"
            },
            {
                "id": "firebase_problem18",
                "title": "Firestore: Debugging",
                "technology": "Firebase",
                "markdown": "### Problem\nFirestore: Debugging\n\n### Solution\nUse Firestore console logs and error messages to debug issues.\n\n```javascript\ntry {\n  const docRef = await addDoc(collection(db, 'users'), { name: 'John Doe' });\n  console.log('Document written with ID: ', docRef.id);\n} catch (e) {\n  console.error('Error adding document: ', e);\n}\n```"
            },
            {
                "id": "firebase_problem19",
                "title": "Firebase Storage: Debugging",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Storage: Debugging\n\n### Solution\nCheck error messages and use console logs for debugging Firebase Storage issues.\n\n```javascript\ngetDownloadURL(storageRef)\n  .catch((error) => {\n    console.error('Error downloading file:', error);\n  });\n```"
            },
            {
                "id": "firebase_problem20",
                "title": "Firebase Hosting: Debugging",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Hosting: Debugging\n\n### Solution\nCheck Firebase Hosting deployment status and logs for debugging.\n\n```bash\nfirebase deploy --only hosting\nfirebase hosting:channel:deploy your-channel-id\n```"
            },
            {
                "id": "firebase_problem21",
                "title": "Firebase Realtime Database: Debugging",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Realtime Database: Debugging\n\n### Solution\nUse `console.log()` to log data and check error messages for debugging.\n\n```javascript\nget(child(dbRef, 'users/' + userId)).catch((error) => {\n  console.error(error);\n});\n```"
            },
            {
                "id": "firebase_problem22",
                "title": "Firebase Functions: Scheduling",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Functions: Scheduling\n\n### Solution\nUse Firebase Functions to schedule tasks using `pubsub`.\n\n```javascript\nconst functions = require('firebase-functions');\n\nexports.scheduledFunction = functions.pubsub.schedule('every 5 minutes').onRun((context) => {\n  console.log('This will be run every 5 minutes!');\n  return null;\n});\n```"
            },
            {
                "id": "firebase_problem23",
                "title": "Firestore: Offline Capabilities",
                "technology": "Firebase",
                "markdown": "### Problem\nFirestore: Offline Capabilities\n\n### Solution\nEnable offline data persistence in Firestore.\n\n```javascript\nimport { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';\n\nconst db = getFirestore();\nenableIndexedDbPersistence(db)\n  .catch((err) => {\n    if (err.code === 'failed-precondition') {\n      console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.');\n    } else if (err.code === 'unimplemented') {\n      console.error('The current browser does not support all of the features required to enable persistence');\n    }\n  });\n```"
            },
            {
                "id": "firebase_problem24",
                "title": "Firebase Authentication: Password Reset",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Authentication: Password Reset\n\n### Solution\nUse Firebase Authentication to send password reset emails.\n\n```javascript\nimport { getAuth, sendPasswordResetEmail } from 'firebase/auth';\n\nconst auth = getAuth();\nsendPasswordResetEmail(auth, email)\n  .then(() => {\n    console.log('Password reset email sent!');\n  })\n  .catch((error) => {\n    console.error('Error sending password reset email:', error);\n  });\n```"
            },
            {
                "id": "firebase_problem25",
                "title": "Firebase Performance Monitoring",
                "technology": "Firebase",
                "markdown": "### Problem\nFirebase Performance Monitoring\n\n### Solution\nUse Firebase Performance Monitoring to track performance issues.\n\n```javascript\nimport { initializeApp } from 'firebase/app';\nimport { getPerformance } from 'firebase/performance';\n\nconst app = initializeApp(firebaseConfig);\nconst perf = getPerformance(app);\n```"
            }
        ]
    );
}