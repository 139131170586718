import React from 'react';
import {createRoot} from 'react-dom/client';
import {CssBaseline, ThemeProvider, createTheme} from "@mui/material";
import './styles.css';
import {Index as Home} from "./pages/home";
import {Index as Blog} from "./pages/blog";
import {
    Routes,
    Route, BrowserRouter,
} from "react-router-dom";

const container = document.getElementById('root');
const root = createRoot(container!);

const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#f8f9fa',
                    minHeight: '48px !important',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: '48px  !important',
                },
            },
        },
    },
    typography: {
        fontFamily: 'Raleway, Arial, sans-serif',
    },
});


root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline>
                <BrowserRouter>
                    <Routes>
                        <Route path="/blog" element={<Blog/>}/>
                        <Route path="*" element={<Home/>}/>
                    </Routes>
                </BrowserRouter>
            </CssBaseline>
        </ThemeProvider>
    </React.StrictMode>
);
