export const config = () => {
    return ([
            {
                "id": "openai_problem1",
                "title": "Setting Up OpenAI API",
                "technology": "OpenAI",
                "markdown": "### Problem\nSetting Up OpenAI API\n\n### Solution\nTo start using the OpenAI API, you need to install the OpenAI Python client library and obtain an API key from OpenAI.\n\n```bash\npip install openai\n```\n\n1. Sign up on the OpenAI platform and generate an API key.\n2. Use the API key in your application:\n\n```python\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\nresponse = openai.Completion.create(\n  engine=\"davinci\",\n  prompt=\"Say this is a test\",\n  max_tokens=5\n)\nprint(response.choices[0].text.strip())\n```\n"
            },
            {
                "id": "openai_problem2",
                "title": "Handling Rate Limits",
                "technology": "OpenAI",
                "markdown": "### Problem\nHandling Rate Limits\n\n### Solution\nOpenAI imposes rate limits to ensure fair usage of the API. If you exceed the rate limits, you'll receive a rate limit error.\n\nTo handle rate limits:\n1. Monitor the `Retry-After` header in API responses.\n2. Implement exponential backoff in your requests.\n\n```python\nimport time\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\n\nfor i in range(5):\n  try:\n    response = openai.Completion.create(engine=\"davinci\", prompt=\"Say this is a test\", max_tokens=5)\n    print(response.choices[0].text.strip())\n    break\n  except openai.error.RateLimitError as e:\n    retry_after = int(e.headers.get(\"Retry-After\", 1))\n    time.sleep(retry_after)\n```\n"
            },
            {
                "id": "openai_problem3",
                "title": "Generating Text with OpenAI",
                "technology": "OpenAI",
                "markdown": "### Problem\nGenerating Text with OpenAI\n\n### Solution\nUse the `Completion` endpoint to generate text. You can customize the generation by adjusting parameters like `max_tokens`, `temperature`, and `n`.\n\n```python\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\nresponse = openai.Completion.create(\n  engine=\"davinci\",\n  prompt=\"Once upon a time\",\n  max_tokens=50,\n  temperature=0.7,\n  n=1\n)\n\nprint(response.choices[0].text.strip())\n```\n"
            },
            {
                "id": "openai_problem4",
                "title": "Fine-tuning Models",
                "technology": "OpenAI",
                "markdown": "### Problem\nFine-tuning Models\n\n### Solution\nFine-tuning allows you to customize a model with your own dataset to improve its performance on specific tasks.\n\n1. Prepare your dataset in the required JSONL format.\n2. Use the OpenAI CLI to upload your dataset and create a fine-tuned model.\n\n```bash\nopenai tools fine_tunes.prepare_data -f your_data.jsonl\nopenai api fine_tunes.create -t your_data_prepared.jsonl -m davinci\n```\n\n3. Use the fine-tuned model:\n\n```python\nresponse = openai.Completion.create(\n  model=\"fine-tuned-model-id\",\n  prompt=\"Custom prompt\",\n  max_tokens=50\n)\nprint(response.choices[0].text.strip())\n```\n"
            },
            {
                "id": "openai_problem5",
                "title": "Error Handling and Debugging",
                "technology": "OpenAI",
                "markdown": "### Problem\nError Handling and Debugging\n\n### Solution\nImplement proper error handling to manage common API errors like authentication, rate limits, and server errors.\n\n```python\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\n\ntry:\n  response = openai.Completion.create(\n    engine=\"davinci\",\n    prompt=\"This is a test\",\n    max_tokens=5\n  )\n  print(response.choices[0].text.strip())\nexcept openai.error.AuthenticationError:\n  print(\"Authentication Error: Check your API key.\")\nexcept openai.error.RateLimitError:\n  print(\"Rate Limit Exceeded: Slow down your requests.\")\nexcept openai.error.OpenAIError as e:\n  print(f\"OpenAI Error: {e}\")\n```\n"
            },
            {
                "id": "openai_problem6",
                "title": "Using the Chat API",
                "technology": "OpenAI",
                "markdown": "### Problem\nUsing the Chat API\n\n### Solution\nThe Chat API allows you to have interactive conversations with the model.\n\n```python\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\n\nresponse = openai.ChatCompletion.create(\n  model=\"gpt-3.5-turbo\",\n  messages=[\n    {\"role\": \"system\", \"content\": \"You are a helpful assistant.\"},\n    {\"role\": \"user\", \"content\": \"Tell me a joke.\"}\n  ]\n)\n\nprint(response.choices[0].message['content'].strip())\n```\n"
            },
            {
                "id": "openai_problem7",
                "title": "Image Generation with DALL-E",
                "technology": "OpenAI",
                "markdown": "### Problem\nImage Generation with DALL-E\n\n### Solution\nUse the DALL-E API to generate images from textual descriptions.\n\n```python\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\n\nresponse = openai.Image.create(\n  prompt=\"A futuristic cityscape at sunset\",\n  n=1,\n  size=\"1024x1024\"\n)\n\nimage_url = response['data'][0]['url']\nprint(image_url)\n```\n"
            },
            {
                "id": "openai_problem8",
                "title": "Content Filtering",
                "technology": "OpenAI",
                "markdown": "### Problem\nContent Filtering\n\n### Solution\nUse the OpenAI content filter to ensure generated content is safe and appropriate.\n\n```python\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\n\nresponse = openai.Completion.create(\n  engine=\"content-filter-alpha\",\n  prompt=\"<|endoftext|>This is a test. <|endoftext|>\",\n  max_tokens=1\n)\n\noutput_label = response.choices[0].text.strip()\nif output_label == \"2\":\n  print(\"Content flagged as unsafe.\")\nelse:\n  print(\"Content is safe.\")\n```\n"
            },
            {
                "id": "openai_problem9",
                "title": "Using OpenAI with GPT-3 in Different Languages",
                "technology": "OpenAI",
                "markdown": "### Problem\nUsing OpenAI with GPT-3 in Different Languages\n\n### Solution\nOpenAI's models support multiple languages. You can generate text in languages other than English by simply providing prompts in the desired language.\n\n```python\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\n\nresponse = openai.Completion.create(\n  engine=\"davinci\",\n  prompt=\"Escribe un poema sobre el mar\",\n  max_tokens=50,\n  temperature=0.7\n)\n\nprint(response.choices[0].text.strip())\n```\n"
            },
            {
                "id": "openai_problem10",
                "title": "Sentiment Analysis with OpenAI",
                "technology": "OpenAI",
                "markdown": "### Problem\nSentiment Analysis with OpenAI\n\n### Solution\nUse OpenAI's API to perform sentiment analysis on text by providing a prompt that guides the model.\n\n```python\nimport openai\n\nopenai.api_key = 'YOUR_API_KEY'\n\nresponse = openai.Completion.create(\n  engine=\"davinci\",\n  prompt=\"Classify the sentiment of this text: 'I love using OpenAI's API!' Sentiment:\",\n  max_tokens=1\n)\n\nsentiment = response.choices[0].text.strip()\nprint(f\"Sentiment: {sentiment}\")\n```\n"
            }
        ]
    );
}