import React from 'react';
import {Tab, Box, Grid, Typography, Divider, Stack, Container} from '@mui/material';
import {TabList, TabPanel, TabContext} from '@mui/lab';
import {Header} from "./components/Header";
import {EntryList} from "./components/EntryList";
import {config as reactConfig} from './components/Entries/React.config';
import {config as typescriptConfig} from './components/Entries/Typescript.config';
import {config as phpConfig} from './components/Entries/Php.config';
import {config as mysqlConfig} from './components/Entries/Mysql.config';
import {config as googleFirebaseConfig} from './components/Entries/GoogleFirebase.config';
import {config as netlifyConfig} from './components/Entries/Netlify.config';
import {config as blockChainConfig} from './components/Entries/BlockChain.config';
import {config as openAiConfig} from './components/Entries/OpenAi.config';

export const Index: React.FC = () => {
    const [tabValue, setTabValue] = React.useState<string>('1');

    return (<React.Fragment>
        <Header/>
        <TabContext value={tabValue}>
            <Grid container marginTop={2}>
                <Grid item xs={3} md={2}>
                    <Box sx={{borderBottom: 0, borderColor: 'divider'}}>
                        <Stack spacing={1}>
                            <Typography variant="h6" component={'h2'} align="center">
                                Filters
                            </Typography>
                            <Divider/>
                            <TabList onChange={(e: React.SyntheticEvent, newValue: string) => setTabValue(newValue)}
                                     aria-label="Navigation Tabs"
                                     orientation={'vertical'}>
                                <Tab label="ReactJs" value="1"/>
                                <Tab label="Typescript" value="2"/>
                                <Tab label="PHP" value="3"/>
                                <Tab label="Databases" value="4"/>
                                <Tab label="Google Firebase" value="5"/>
                                <Tab label="Netlify" value="6"/>
                                <Tab label="Block Chain" value="7"/>
                                <Tab label="Open AI" value="8"/>
                            </TabList>
                        </Stack>

                    </Box>
                </Grid>
                <Grid item xs={9} md={10}>
                    <Container>
                        <Stack spacing={2}>
                            <Typography variant="h3" component={'h1'} align="left">
                                Welcome
                            </Typography>
                            <Typography variant="subtitle2" component={'p'} align="left">
                                Welcome to our comprehensive guide to solving common tech problems!
                                Whether you're a beginner or an experienced professional, technology can present various
                                challenges. Our
                                blog is here to help you navigate these hurdles with ease.
                            </Typography>
                        </Stack>
                    </Container>
                    <TabPanel value="1">
                        <EntryList data={reactConfig()} label={"ReactJS"} id={"reactjs"}/>
                    </TabPanel>
                    <TabPanel value="2">
                        <EntryList data={typescriptConfig()} label={"Typescript"} id={"typescript"}/>
                    </TabPanel>
                    <TabPanel value="3">
                        <EntryList data={phpConfig()} label={"PHP"} id={"php"}/>
                    </TabPanel>
                    <TabPanel value="4">
                        <EntryList data={mysqlConfig()} label={"Mysql"} id={"mysql"}/>
                    </TabPanel>
                    <TabPanel value="5">
                        <EntryList data={googleFirebaseConfig()} label={"Firebase"} id={"firebase"}/>
                    </TabPanel>
                    <TabPanel value="6">
                        <EntryList data={netlifyConfig()} label={"Netlify"} id={"netlify"}/>
                    </TabPanel>
                    <TabPanel value={"7"}>
                        <EntryList data={blockChainConfig()} label={"BlockChain"} id={"blockchain"}/>
                    </TabPanel>
                    <TabPanel value={"8"}>
                        <EntryList data={openAiConfig()} label={"Open Ai"} id={"openai"}/>
                    </TabPanel>
                </Grid>
            </Grid>
        </TabContext>
    </React.Fragment>);
}