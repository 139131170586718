import React from 'react';
import {Link, List, ListItem, Typography} from "@mui/material";
import {config} from "../../config";

export const PersonalProjects = () => {
    return (<React.Fragment>
        <Typography variant="h5" component={'h2'} align="left">
            {config().personProjects.title}
        </Typography>
        <List dense={false} className={'personal-projects'}>
            {
                config().personProjects.projectsLinks.map((project, index) => (
                    <ListItem key={index}>
                        <Link href={project.url}>
                            {project.label}
                        </Link>
                    </ListItem>
                ))
            }
        </List>
    </React.Fragment>);
}