import {Divider, Typography} from "@mui/material";
import React from "react";
import {config} from "../config";

export const Title: React.FC = () => {
    return (
        <React.Fragment>
            <Typography variant="h3" component={'h1'} align="left">
                {config().header.title}
                <Typography variant="h4" component={'h2'} align="left">
                    <small>{config().header.tagline}</small>
                </Typography>
            </Typography>
            <Divider/>
        </React.Fragment>
    )
}