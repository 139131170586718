export const config = () => {

    return([
            {
                "id": "blockchain_problem1",
                "title": "Setting Up a Blockchain Development Environment",
                "technology": "Blockchain",
                "markdown": "### Problem\nSetting Up a Blockchain Development Environment\n\n### Solution\nUse tools like Truffle and Ganache to set up your blockchain development environment.\n\n```bash\nnpm install -g truffle\nnpm install -g ganache-cli\n\n# Initialize a new Truffle project\nmkdir my-project\ncd my-project\ntruffle init\n\n# Start Ganache CLI\nganache-cli\n```\n"
            },
            {
                "id": "blockchain_problem2",
                "title": "Creating and Deploying Smart Contracts",
                "technology": "Blockchain",
                "markdown": "### Problem\nCreating and Deploying Smart Contracts\n\n### Solution\nUse Solidity to write smart contracts and Truffle to deploy them.\n\n```solidity\n// contracts/HelloWorld.sol\npragma solidity ^0.8.0;\n\ncontract HelloWorld {\n    string public message;\n\n    constructor(string memory _message) {\n        message = _message;\n    }\n}\n```\n\n```bash\n# Compile the contract\ntruffle compile\n\n# Deploy the contract\ntruffle migrate\n```\n"
            },
            {
                "id": "blockchain_problem3",
                "title": "Interacting with Smart Contracts",
                "technology": "Blockchain",
                "markdown": "### Problem\nInteracting with Smart Contracts\n\n### Solution\nUse Web3.js to interact with deployed smart contracts from a frontend application.\n\n```javascript\nimport Web3 from 'web3';\nimport HelloWorld from './build/contracts/HelloWorld.json';\n\nconst web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');\n\nconst contract = new web3.eth.Contract(\n  HelloWorld.abi,\n  'deployed_contract_address'\n);\n\nconst message = await contract.methods.message().call();\nconsole.log(message);\n```\n"
            },
            {
                "id": "blockchain_problem4",
                "title": "Managing Private Keys Securely",
                "technology": "Blockchain",
                "markdown": "### Problem\nManaging Private Keys Securely\n\n### Solution\nUse hardware wallets or secure key management services to store private keys.\n\n```markdown\n- Use hardware wallets like Ledger or Trezor to store private keys offline.\n- Use services like AWS KMS or Azure Key Vault for secure key management in applications.\n```\n"
            },
            {
                "id": "blockchain_problem5",
                "title": "Blockchain Data Storage",
                "technology": "Blockchain",
                "markdown": "### Problem\nBlockchain Data Storage\n\n### Solution\nUse IPFS for decentralized storage of large files and link the data to blockchain transactions.\n\n```bash\n# Install IPFS\nnpm install ipfs-http-client\n\n# Upload a file to IPFS\nimport { create } from 'ipfs-http-client';\n\nconst ipfs = create('https://ipfs.infura.io:5001');\nconst file = ...; // get file from input\nconst result = await ipfs.add(file);\nconsole.log(result.path); // IPFS hash\n```\n"
            },
            {
                "id": "blockchain_problem6",
                "title": "Handling Gas Fees",
                "technology": "Blockchain",
                "markdown": "### Problem\nHandling Gas Fees\n\n### Solution\nEstimate and manage gas fees using Web3.js to ensure transactions are processed efficiently.\n\n```javascript\nconst gasPrice = await web3.eth.getGasPrice();\nconst gasEstimate = await contract.methods.myMethod().estimateGas({ from: 'your_address' });\n\nawait contract.methods.myMethod().send({ from: 'your_address', gas: gasEstimate, gasPrice: gasPrice });\n```\n"
            },
            {
                "id": "blockchain_problem7",
                "title": "Debugging Smart Contracts",
                "technology": "Blockchain",
                "markdown": "### Problem\nDebugging Smart Contracts\n\n### Solution\nUse Truffle's built-in debugger to debug smart contracts and identify issues.\n\n```bash\n# Deploy the contract in development mode\ntruffle develop\n\n# Migrate the contract\nmigrate\n\n# Debug a transaction\ntruffle debug tx_hash\n```\n"
            },
            {
                "id": "blockchain_problem8",
                "title": "Smart Contract Security Best Practices",
                "technology": "Blockchain",
                "markdown": "### Problem\nSmart Contract Security Best Practices\n\n### Solution\nFollow best practices such as using OpenZeppelin contracts, performing audits, and following the security guidelines.\n\n```markdown\n- Use OpenZeppelin's audited libraries for common contract functionality.\n- Regularly audit your smart contracts using professional services.\n- Follow security best practices such as avoiding `tx.origin`, using `require` statements, and testing extensively.\n```\n"
            },
            {
                "id": "blockchain_problem9",
                "title": "Integrating Blockchain with Frontend Frameworks",
                "technology": "Blockchain",
                "markdown": "### Problem\nIntegrating Blockchain with Frontend Frameworks\n\n### Solution\nUse libraries like Web3.js or Ethers.js to connect blockchain with React or Angular frontend applications.\n\n```javascript\n// Example with React and Web3.js\nimport React, { useEffect, useState } from 'react';\nimport Web3 from 'web3';\n\nconst App = () => {\n  const [account, setAccount] = useState('');\n\n  useEffect(() => {\n    const loadBlockchainData = async () => {\n      const web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');\n      const accounts = await web3.eth.getAccounts();\n      setAccount(accounts[0]);\n    };\n    loadBlockchainData();\n  }, []);\n\n  return (\n    <div>\n      <h1>Your account: {account}</h1>\n    </div>\n  );\n};\n\nexport default App;\n```\n"
            },
            {
                "id": "blockchain_problem10",
                "title": "Handling Blockchain Events",
                "technology": "Blockchain",
                "markdown": "### Problem\nHandling Blockchain Events\n\n### Solution\nUse Web3.js to listen for events emitted by smart contracts and handle them in your application.\n\n```javascript\nimport Web3 from 'web3';\nimport MyContract from './build/contracts/MyContract.json';\n\nconst web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');\nconst contract = new web3.eth.Contract(MyContract.abi, 'deployed_contract_address');\n\ncontract.events.MyEvent({\n  filter: {},\n  fromBlock: 'latest'\n}, function(error, event){ console.log(event); })\n.on('data', function(event){\n  console.log(event); // same results as the optional callback above\n})\n.on('changed', function(event){\n  // remove event from local database\n})\n.on('error', console.error);\n```\n"
            }
        ]
    );

}