/* eslint-disable no-template-curly-in-string */

export const config = () => {

    return (
        [
            {
                "id": "problem1",
                "title": "State Not Updating Immediately",
                "technology": "React",
                "markdown": "### Problem\nState Not Updating Immediately\n\n### Solution\nUse functional state updates to ensure you're working with the latest state.\n\n```javascript\nconst [count, setCount] = useState(0);\n\nconst increment = () => {\n  setCount(prevCount => prevCount + 1);\n};\n```"
            },
            {
                "id": "problem2",
                "title": "Props Drilling",
                "technology": "React",
                "markdown": "### Problem\nProps Drilling\n\n### Solution\nUse Context API to avoid passing props through multiple levels.\n\n```javascript\nconst UserContext = createContext();\n\nconst App = () => (\n  <UserContext.Provider value={/* some value */}>\n    <Component />\n  </UserContext.Provider>\n);\n\nconst Component = () => {\n  const value = useContext(UserContext);\n  return <div>{value}</div>;\n};\n```"
            },
            {
                "id": "problem3",
                "title": "Memory Leak in Component",
                "technology": "React",
                "markdown": "### Problem\nMemory Leak in Component\n\n### Solution\nClean up subscriptions or async calls in `useEffect` to prevent memory leaks.\n\n```javascript\nuseEffect(() => {\n  const subscription = someAPI.subscribe();\n  return () => {\n    subscription.unsubscribe();\n  };\n}, []);\n```"
            },
            {
                "id": "problem4",
                "title": "Conditional Rendering",
                "technology": "React",
                "markdown": "### Problem\nConditional Rendering\n\n### Solution\nUse logical && operator for simple conditions or ternary operator for complex conditions.\n\n```javascript\nconst Component = ({ isLoggedIn }) => (\n  isLoggedIn ? <LogoutButton /> : <LoginButton />\n);\n```"
            },
            {
                "id": "problem5",
                "title": "Handling Forms",
                "technology": "React",
                "markdown": "### Problem\nHandling Forms\n\n### Solution\nUse controlled components to handle form inputs and state.\n\n```javascript\nconst [value, setValue] = useState(\"\");\n\nconst handleChange = (event) => {\n  setValue(event.target.value);\n};\n\n<form>\n  <input type=\"text\" value={value} onChange={handleChange} />\n</form>\n```"
            },
            {
                "id": "problem6",
                "title": "Fetching Data",
                "technology": "React",
                "markdown": "### Problem\nFetching Data\n\n### Solution\nUse `useEffect` to fetch data when component mounts.\n\n```javascript\nuseEffect(() => {\n  fetchData();\n}, []);\n```"
            },
            {
                "id": "problem7",
                "title": "Lifting State Up",
                "technology": "React",
                "markdown": "### Problem\nLifting State Up\n\n### Solution\nMove the state to a common ancestor component and pass it down as props.\n\n```javascript\nconst Parent = () => {\n  const [value, setValue] = useState(\"\");\n  return <Child value={value} setValue={setValue} />;\n};\n```"
            },
            {
                "id": "problem8",
                "title": "Using Refs",
                "technology": "React",
                "markdown": "### Problem\nUsing Refs\n\n### Solution\nUse `useRef` to access DOM elements directly.\n\n```javascript\nconst inputRef = useRef(null);\n\nconst focusInput = () => {\n  inputRef.current.focus();\n};\n\n<input ref={inputRef} />\n<button onClick={focusInput}>Focus Input</button>\n```"
            },
            {
                "id": "problem9",
                "title": "Component Lifecycle",
                "technology": "React",
                "markdown": "### Problem\nComponent Lifecycle\n\n### Solution\nUse `useEffect` to mimic lifecycle methods like `componentDidMount` and `componentWillUnmount`.\n\n```javascript\nuseEffect(() => {\n  // componentDidMount\n  return () => {\n    // componentWillUnmount\n  };\n}, []);\n```"
            },
            {
                "id": "problem10",
                "title": "Error Boundaries",
                "technology": "React",
                "markdown": "### Problem\nError Boundaries\n\n### Solution\nUse Error Boundary component to catch JavaScript errors in child components.\n\n```javascript\nclass ErrorBoundary extends React.Component {\n  constructor(props) {\n    super(props);\n    this.state = { hasError: false };\n  }\n\n  static getDerivedStateFromError(error) {\n    return { hasError: true };\n  }\n\n  componentDidCatch(error, errorInfo) {\n    // log error\n  }\n\n  render() {\n    if (this.state.hasError) {\n      return <h1>Something went wrong.</h1>;\n    }\n    return this.props.children; \n  }\n}\n```"
            },
            {
                "id": "problem11",
                "title": "Using Keys in Lists",
                "technology": "React",
                "markdown": "### Problem\nUsing Keys in Lists\n\n### Solution\nUse a unique key prop to help React identify which items have changed.\n\n```javascript\nconst listItems = items.map(item =>\n  <li key={item.id}>{item.name}</li>\n);\n```"
            },
            {
                "id": "problem12",
                "title": "React Router",
                "technology": "React",
                "markdown": "### Problem\nReact Router\n\n### Solution\nUse `react-router-dom` for declarative routing in your React apps.\n\n```javascript\nimport { BrowserRouter as Router, Route, Switch } from 'react-router-dom';\n\nconst App = () => (\n  <Router>\n    <Switch>\n      <Route path=\"/home\" component={Home} />\n      <Route path=\"/about\" component={About} />\n    </Switch>\n  </Router>\n);\n```"
            },
            {
                "id": "problem13",
                "title": "Code Splitting",
                "technology": "React",
                "markdown": "### Problem\nCode Splitting\n\n### Solution\nUse dynamic `import` to split your code into smaller chunks.\n\n```javascript\nconst OtherComponent = React.lazy(() => import('./OtherComponent'));\n\n<Suspense fallback={<div>Loading...</div>}>\n  <OtherComponent />\n</Suspense>\n```"
            },
            {
                "id": "problem14",
                "title": "Context API",
                "technology": "React",
                "markdown": "### Problem\nContext API\n\n### Solution\nUse Context API to share state across components without passing props.\n\n```javascript\nconst MyContext = React.createContext();\n\nconst App = () => {\n  const [state, setState] = useState(\"\");\n  return (\n    <MyContext.Provider value={{ state, setState }}>\n      <Component />\n    </MyContext.Provider>\n  );\n};\n\nconst Component = () => {\n  const { state, setState } = useContext(MyContext);\n  return <div>{state}</div>;\n};\n```"
            },
            {
                "id": "problem15",
                "title": "PropTypes",
                "technology": "React",
                "markdown": "### Problem\nPropTypes\n\n### Solution\nUse PropTypes to enforce typechecking in your components.\n\n```javascript\nimport PropTypes from 'prop-types';\n\nconst MyComponent = ({ name }) => (\n  <div>{name}</div>\n);\n\nMyComponent.propTypes = {\n  name: PropTypes.string.isRequired,\n};\n```"
            },
            {
                "id": "problem16",
                "title": "Optimizing Performance",
                "technology": "React",
                "markdown": "### Problem\nOptimizing Performance\n\n### Solution\nUse `React.memo` to prevent unnecessary re-renders.\n\n```javascript\nconst MyComponent = React.memo(({ name }) => (\n  <div>{name}</div>\n));\n```"
            },
            {
                "id": "problem17",
                "title": "UseReducer Hook",
                "technology": "React",
                "markdown": "### Problem\nUseReducer Hook\n\n### Solution\nUse `useReducer` for more complex state logic.\n\n```javascript\nconst initialState = { count: 0 };\n\nfunction reducer(state, action) {\n  switch (action.type) {\n    case 'increment':\n      return { count: state.count + 1 };\n    case 'decrement':\n      return { count: state.count - 1 };\n    default:\n      throw new Error();\n  }\n}\n\nconst Counter = () => {\n  const [state, dispatch] = useReducer(reducer, initialState);\n  return (\n    <>\n      Count: {state.count}\n      <button onClick={() => dispatch({ type: 'increment' })}>+</button>\n      <button onClick={() => dispatch({ type: 'decrement' })}>-</button>\n    </>\n  );\n};\n```"
            },
            {
                "id": "problem18",
                "title": "Custom Hooks",
                "technology": "React",
                "markdown": "### Problem\nCustom Hooks\n\n### Solution\nCreate custom hooks to reuse logic across components.\n\n```javascript\nconst useFetch = (url) => {\n  const [data, setData] = useState(null);\n  useEffect(() => {\n    fetch(url)\n      .then(response => response.json())\n      .then(data => setData(data));\n  }, [url]);\n  return data;\n};\n```"
            },
            {
                "id": "problem19",
                "title": "Handling Side Effects",
                "technology": "React",
                "markdown": "### Problem\nHandling Side Effects\n\n### Solution\nUse `useEffect` to handle side effects like fetching data or directly updating the DOM.\n\n```javascript\nuseEffect(() => {\n  document.title = `You clicked ${count} times`;\n}, [count]);\n```"
            },
            {
                "id": "problem20",
                "title": "Styling Components",
                "technology": "React",
                "markdown": "### Problem\nStyling Components\n\n### Solution\nUse CSS-in-JS libraries like styled-components for scoped and dynamic styling.\n\n```javascript\nimport styled from 'styled-components';\n\nconst Button = styled.button`\n  background: ${props => props.primary ? 'blue' : 'gray'};\n  color: white;\n`;\n```"
            },
            {
                "id": "problem21",
                "title": "Using Fragments",
                "technology": "React",
                "markdown": "### Problem\nUsing Fragments\n\n### Solution\nUse React Fragments to group multiple elements without adding extra nodes to the DOM.\n\n```javascript\nreturn (\n  <>\n    <h1>Title</h1>\n    <p>Description</p>\n  </>\n);\n```"
            },
            {
                "id": "problem22",
                "title": "Higher-Order Components",
                "technology": "React",
                "markdown": "### Problem\nHigher-Order Components\n\n### Solution\nUse HOCs to reuse component logic.\n\n```javascript\nconst withLogging = (WrappedComponent) => {\n  return (props) => {\n    useEffect(() => {\n      console.log('Component mounted');\n    }, []);\n    return <WrappedComponent {...props} />;\n  };\n};\n```"
            },
            {
                "id": "problem23",
                "title": "Portals",
                "technology": "React",
                "markdown": "### Problem\nPortals\n\n### Solution\nUse React Portals to render children into a DOM node outside of the parent component.\n\n```javascript\nreturn ReactDOM.createPortal(\n  <Child />, \n  document.getElementById('modal-root')\n);\n```"
            },
            {
                "id": "problem24",
                "title": "Strict Mode",
                "technology": "React",
                "markdown": "### Problem\nStrict Mode\n\n### Solution\nUse React Strict Mode to highlight potential problems in your application.\n\n```javascript\n<React.StrictMode>\n  <App />\n</React.StrictMode>\n```"
            }
        ]
    );
}