import React from "react";
import {Title} from "./components/Title";
import {HeaderVideo} from "./components/HeaderVideo";
import {Main} from "./components/Main";
import {Container, Stack, useMediaQuery} from "@mui/material";
import {Header} from "./components/Header";
import Footer from "./components/Footer";

export const Index: React.FC = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    return (<React.Fragment>
        <React.Fragment>
            <Header/>
            <Container maxWidth={(isSmallScreen) ? 'md' : 'lg'}
                       sx={{marginBottom: 6}}>
                <Stack spacing={4} marginTop={4}>
                    <Title/>
                    <HeaderVideo/>
                    <Main/>
                </Stack>
            </Container>
            <Footer/>
        </React.Fragment>

    </React.Fragment>);
}