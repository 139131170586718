/* eslint-disable no-template-curly-in-string */

export const config = () => {

    return ([
            {
                "id": "php_problem1",
                "title": "Undefined Variable",
                "technology": "PHP",
                "markdown": "### Problem\nUndefined Variable\n\n### Solution\nEnsure all variables are initialized before use.\n\n```php\n$var = 'Hello';\necho $var; // No error\n```"
            },
            {
                "id": "php_problem2",
                "title": "SQL Injection",
                "technology": "PHP",
                "markdown": "### Problem\nSQL Injection\n\n### Solution\nUse prepared statements to prevent SQL injection.\n\n```php\n$stmt = $pdo->prepare('SELECT * FROM users WHERE email = ?');\n$stmt->execute([$email]);\n```"
            },
            {
                "id": "php_problem3",
                "title": "Deprecated Functions",
                "technology": "PHP",
                "markdown": "### Problem\nDeprecated Functions\n\n### Solution\nUpdate code to use the latest functions.\n\n```php\n// Old way\nerase($filename);\n\n// New way\nunlink($filename);\n```"
            },
            {
                "id": "php_problem4",
                "title": "Cross-Site Scripting (XSS)",
                "technology": "PHP",
                "markdown": "### Problem\nCross-Site Scripting (XSS)\n\n### Solution\nSanitize user inputs to prevent XSS attacks.\n\n```php\necho htmlspecialchars($user_input, ENT_QUOTES, 'UTF-8');\n```"
            },
            {
                "id": "php_problem5",
                "title": "File Upload Security",
                "technology": "PHP",
                "markdown": "### Problem\nFile Upload Security\n\n### Solution\nValidate and sanitize file uploads.\n\n```php\nif (isset($_FILES['file'])) {\n  $file = $_FILES['file'];\n  $allowed_types = ['image/jpeg', 'image/png'];\n  if (in_array($file['type'], $allowed_types)) {\n    move_uploaded_file($file['tmp_name'], 'uploads/' . basename($file['name']));\n  }\n}\n```"
            },
            {
                "id": "php_problem6",
                "title": "Error Handling",
                "technology": "PHP",
                "markdown": "### Problem\nError Handling\n\n### Solution\nUse try-catch blocks to handle exceptions.\n\n```php\ntry {\n  // Code that may throw an exception\n} catch (Exception $e) {\n  echo 'Caught exception: ',  $e->getMessage(), \"\\n\";\n}\n```"
            },
            {
                "id": "php_problem7",
                "title": "Session Management",
                "technology": "PHP",
                "markdown": "### Problem\nSession Management\n\n### Solution\nAlways start a session at the beginning of your script.\n\n```php\nsession_start();\n$_SESSION['user'] = 'John';\necho $_SESSION['user'];\n```"
            },
            {
                "id": "php_problem8",
                "title": "Undefined Index",
                "technology": "PHP",
                "markdown": "### Problem\nUndefined Index\n\n### Solution\nCheck if the array index exists before using it.\n\n```php\nif (isset($_POST['username'])) {\n  $username = $_POST['username'];\n} else {\n  $username = '';\n}\n```"
            },
            {
                "id": "php_problem9",
                "title": "Sending Emails",
                "technology": "PHP",
                "markdown": "### Problem\nSending Emails\n\n### Solution\nUse `mail()` function to send emails.\n\n```php\n$to = 'user@example.com';\n$subject = 'Test Email';\n$message = 'Hello, this is a test email.';\n$headers = 'From: webmaster@example.com';\nmail($to, $subject, $message, $headers);\n```"
            },
            {
                "id": "php_problem10",
                "title": "Password Hashing",
                "technology": "PHP",
                "markdown": "### Problem\nPassword Hashing\n\n### Solution\nUse `password_hash()` to securely hash passwords.\n\n```php\n$hashed_password = password_hash($password, PASSWORD_DEFAULT);\n```"
            },
            {
                "id": "php_problem11",
                "title": "Password Verification",
                "technology": "PHP",
                "markdown": "### Problem\nPassword Verification\n\n### Solution\nUse `password_verify()` to verify hashed passwords.\n\n```php\nif (password_verify($password, $hashed_password)) {\n  echo 'Password is valid!';\n} else {\n  echo 'Invalid password.';\n}\n```"
            },
            {
                "id": "php_problem12",
                "title": "CSRF Protection",
                "technology": "PHP",
                "markdown": "### Problem\nCSRF Protection\n\n### Solution\nUse tokens to protect against CSRF attacks.\n\n```php\nsession_start();\n$token = bin2hex(random_bytes(32));\n$_SESSION['token'] = $token;\n```\n\n```html\n<form method=\"post\">\n  <input type=\"hidden\" name=\"token\" value=\"<?php echo $_SESSION['token']; ?>\">\n  <!-- form fields -->\n</form>\n```"
            },
            {
                "id": "php_problem13",
                "title": "Handling JSON Data",
                "technology": "PHP",
                "markdown": "### Problem\nHandling JSON Data\n\n### Solution\nUse `json_encode()` and `json_decode()` to work with JSON data.\n\n```php\n$data = ['name' => 'John', 'age' => 25];\n$json_data = json_encode($data);\n$array_data = json_decode($json_data, true);\n```"
            },
            {
                "id": "php_problem14",
                "title": "Environment Variables",
                "technology": "PHP",
                "markdown": "### Problem\nEnvironment Variables\n\n### Solution\nUse `getenv()` and `putenv()` to manage environment variables.\n\n```php\nputenv('DB_HOST=localhost');\n$db_host = getenv('DB_HOST');\n```"
            },
            {
                "id": "php_problem15",
                "title": "PDO for Database Interaction",
                "technology": "PHP",
                "markdown": "### Problem\nPDO for Database Interaction\n\n### Solution\nUse PDO for secure and flexible database interactions.\n\n```php\n$dsn = 'mysql:host=localhost;dbname=testdb';\n$username = 'root';\n$password = '';\n$options = [];\n$pdo = new PDO($dsn, $username, $password, $options);\n```"
            },
            {
                "id": "php_problem16",
                "title": "Redirecting Pages",
                "technology": "PHP",
                "markdown": "### Problem\nRedirecting Pages\n\n### Solution\nUse `header()` function to redirect to another page.\n\n```php\nheader('Location: /new_page.php');\nexit();\n```"
            },
            {
                "id": "php_problem17",
                "title": "Date and Time",
                "technology": "PHP",
                "markdown": "### Problem\nDate and Time\n\n### Solution\nUse `DateTime` class to work with dates and times.\n\n```php\n$date = new DateTime();\necho $date->format('Y-m-d H:i:s');\n```"
            },
            {
                "id": "php_problem18",
                "title": "File Handling",
                "technology": "PHP",
                "markdown": "### Problem\nFile Handling\n\n### Solution\nUse `fopen()`, `fread()`, and `fwrite()` for file operations.\n\n```php\n$file = fopen('file.txt', 'r');\n$content = fread($file, filesize('file.txt'));\nfclose($file);\n```"
            },
            {
                "id": "php_problem19",
                "title": "Regular Expressions",
                "technology": "PHP",
                "markdown": "### Problem\nRegular Expressions\n\n### Solution\nUse `preg_match()` to perform regular expression matching.\n\n```php\n$pattern = '/^abc/';\n$string = 'abcdef';\nif (preg_match($pattern, $string)) {\n  echo 'Match found';\n}\n```"
            },
            {
                "id": "php_problem20",
                "title": "Error Reporting",
                "technology": "PHP",
                "markdown": "### Problem\nError Reporting\n\n### Solution\nEnable error reporting for development.\n\n```php\nini_set('display_errors', 1);\nini_set('display_startup_errors', 1);\nerror_reporting(E_ALL);\n```"
            },
            {
                "id": "php_problem21",
                "title": "Autoloading Classes",
                "technology": "PHP",
                "markdown": "### Problem\nAutoloading Classes\n\n### Solution\nUse `spl_autoload_register()` to autoload classes.\n\n```php\nspl_autoload_register(function ($class_name) {\n  include $class_name . '.php';\n});\n```"
            },
            {
                "id": "php_problem22",
                "title": "Namespaces",
                "technology": "PHP",
                "markdown": "### Problem\nNamespaces\n\n### Solution\nUse namespaces to avoid naming conflicts.\n\n```php\nnamespace MyApp;\n\nclass User {\n  public function __construct() {\n    echo 'User class';\n  }\n}\n```"
            },
            {
                "id": "php_problem23",
                "title": "Using Composer",
                "technology": "PHP",
                "markdown": "### Problem\nUsing Composer\n\n### Solution\nUse Composer to manage dependencies.\n\n```json\n{\n  \"require\": {\n    \"monolog/monolog\": \"^2.0\"\n  }\n}\n```"
            },
            {
                "id": "php_problem24",
                "title": "Traits",
                "technology": "PHP",
                "markdown": "### Problem\nTraits\n\n### Solution\nUse traits to reuse methods across multiple classes.\n\n```php\ntrait Logger {\n  public function log($message) {\n    echo $message;\n  }\n}\n\nclass User {\n  use Logger;\n}\n```"
            },
            {
                "id": "php_problem25",
                "title": "Type Hinting",
                "technology": "PHP",
                "markdown": "### Problem\nType Hinting\n\n### Solution\nUse type hinting to specify the expected data type of arguments.\n\n```php\nfunction setAge(int $age) {\n  // ...\n}\n```"
            }
        ]
    );
}