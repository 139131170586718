import React from "react";
import {Box, Grid} from "@mui/material";
import {ContentBox} from "./Main/ContentBox";
import {PersonalProjects} from "./Main/PersonalProjects";
import {ExampleCode} from "./Main/ExampleCode";

export const Main: React.FC = () => {
    return (<Grid container spacing={2}>
        <Grid item xs={11} md={7}>
            <Box sx={{p: 2, border: '1px solid lightgrey', backgroundColor: '#f5f5f5', borderRadius: 2}}>
                <ContentBox/>
            </Box>
        </Grid>
        <Grid item xs={12} md={5}>
            <PersonalProjects/>
            <ExampleCode />
        </Grid>
    </Grid>)
}