/* eslint-disable no-template-curly-in-string */

export const config = () => {

    return ([{
        "id": "ts_problem2",
        "title": "Defining Interfaces",
        "technology": "TypeScript",
        "markdown": "### Problem\nDefining Interfaces\n\n### Solution\nUse interfaces to define the shape of an object.\n\n```typescript\ninterface User {\n  name: string;\n  age: number;\n}\n\nconst user: User = {\n  name: \"John\",\n  age: 25\n};\n```"
    },
        {
            "id": "ts_problem3",
            "title": "Union Types",
            "technology": "TypeScript",
            "markdown": "### Problem\nUnion Types\n\n### Solution\nUse union types to allow a variable to hold more than one type.\n\n```typescript\nlet value: string | number;\nvalue = \"Hello\";\nvalue = 10;\n```"
        },
        {
            "id": "ts_problem4",
            "title": "Optional Properties",
            "technology": "TypeScript",
            "markdown": "### Problem\nOptional Properties\n\n### Solution\nUse a question mark to denote optional properties in interfaces.\n\n```typescript\ninterface User {\n  name: string;\n  age?: number;\n}\n\nconst user: User = {\n  name: \"John\"\n};\n```"
        },
        {
            "id": "ts_problem5",
            "title": "Type Assertions",
            "technology": "TypeScript",
            "markdown": "### Problem\nType Assertions\n\n### Solution\nUse type assertions to tell the compiler to treat a variable as a different type.\n\n```typescript\nlet value: any = \"Hello\";\nlet strLength: number = (value as string).length;\n```"
        },
        {
            "id": "ts_problem6",
            "title": "Enums",
            "technology": "TypeScript",
            "markdown": "### Problem\nEnums\n\n### Solution\nUse enums to define a set of named constants.\n\n```typescript\nenum Direction {\n  Up,\n  Down,\n  Left,\n  Right\n}\n\nlet dir: Direction = Direction.Up;\n```"
        },
        {
            "id": "ts_problem7",
            "title": "Generics",
            "technology": "TypeScript",
            "markdown": "### Problem\nGenerics\n\n### Solution\nUse generics to create reusable components that work with any data type.\n\n```typescript\nfunction identity<T>(arg: T): T {\n  return arg;\n}\n\nlet output = identity<string>(\"Hello\");\n```"
        },
        {
            "id": "ts_problem8",
            "title": "Intersection Types",
            "technology": "TypeScript",
            "markdown": "### Problem\nIntersection Types\n\n### Solution\nUse intersection types to combine multiple types into one.\n\n```typescript\ninterface Person {\n  name: string;\n}\n\ninterface Employee {\n  id: number;\n}\n\nlet employee: Person & Employee = {\n  name: \"John\",\n  id: 1\n};\n```"
        },
        {
            "id": "ts_problem9",
            "title": "Readonly Properties",
            "technology": "TypeScript",
            "markdown": "### Problem\nReadonly Properties\n\n### Solution\nUse the `readonly` keyword to make properties immutable.\n\n```typescript\ninterface User {\n  readonly id: number;\n  name: string;\n}\n\nlet user: User = {\n  id: 1,\n  name: \"John\"\n};\n\n// user.id = 2; // Error\n```"
        },
        {
            "id": "ts_problem10",
            "title": "Function Types",
            "technology": "TypeScript",
            "markdown": "### Problem\nFunction Types\n\n### Solution\nUse function types to define the signature of a function.\n\n```typescript\ninterface SearchFunc {\n  (source: string, subString: string): boolean;\n}\n\nlet mySearch: SearchFunc;\nmySearch = function(src, sub) {\n  return src.includes(sub);\n};\n```"
        },
        {
            "id": "ts_problem11",
            "title": "Default Parameters",
            "technology": "TypeScript",
            "markdown": "### Problem\nDefault Parameters\n\n### Solution\nUse default parameters to provide default values for function parameters.\n\n```typescript\nfunction buildName(firstName: string, lastName = \"Smith\") {\n  return `${firstName} ${lastName}`;\n}\n\nlet result1 = buildName(\"Bob\");\n```"
        },
        {
            "id": "ts_problem12",
            "title": "Destructuring",
            "technology": "TypeScript",
            "markdown": "### Problem\nDestructuring\n\n### Solution\nUse destructuring to extract values from arrays or properties from objects.\n\n```typescript\nlet user = { name: \"John\", age: 25 };\nlet { name, age } = user;\n```"
        },
        {
            "id": "ts_problem13",
            "title": "Tuple Types",
            "technology": "TypeScript",
            "markdown": "### Problem\nTuple Types\n\n### Solution\nUse tuples to create an array with a fixed number of elements of specific types.\n\n```typescript\nlet tuple: [string, number];\ntuple = [\"Hello\", 10];\n```"
        },
        {
            "id": "ts_problem14",
            "title": "Access Modifiers",
            "technology": "TypeScript",
            "markdown": "### Problem\nAccess Modifiers\n\n### Solution\nUse `public`, `private`, and `protected` to control the visibility of class members.\n\n```typescript\nclass User {\n  private name: string;\n  constructor(name: string) {\n    this.name = name;\n  }\n  getName() {\n    return this.name;\n  }\n}\n```"
        },
        {
            "id": "ts_problem15",
            "title": "Static Properties",
            "technology": "TypeScript",
            "markdown": "### Problem\nStatic Properties\n\n### Solution\nUse the `static` keyword to define properties and methods on the class itself rather than instances of the class.\n\n```typescript\nclass Circle {\n  static pi: number = 3.14;\n  constructor(public radius: number) {}\n}\n\nconsole.log(Circle.pi);\n```"
        },
        {
            "id": "ts_problem16",
            "title": "Abstract Classes",
            "technology": "TypeScript",
            "markdown": "### Problem\nAbstract Classes\n\n### Solution\nUse abstract classes as base classes for other classes.\n\n```typescript\nabstract class Animal {\n  abstract makeSound(): void;\n  move(): void {\n    console.log(\"Moving...\");\n  }\n}\n\nclass Dog extends Animal {\n  makeSound() {\n    console.log(\"Bark\");\n  }\n}\n```"
        },
        {
            "id": "ts_problem17",
            "title": "Non-null Assertion Operator",
            "technology": "TypeScript",
            "markdown": "### Problem\nNon-null Assertion Operator\n\n### Solution\nUse the non-null assertion operator (`!`) to tell the compiler that a value is not null or undefined.\n\n```typescript\nlet value: string | null = null;\nconsole.log(value!.length); // no error\n```"
        },
        {
            "id": "ts_problem18",
            "title": "Type Guards",
            "technology": "TypeScript",
            "markdown": "### Problem\nType Guards\n\n### Solution\nUse type guards to narrow down the type within a conditional block.\n\n```typescript\nfunction isString(value: any): value is string {\n  return typeof value === \"string\";\n}\n\nlet value: any = \"Hello\";\nif (isString(value)) {\n  console.log(value.length);\n}\n```"
        },
        {
            "id": "ts_problem19",
            "title": "Declaration Merging",
            "technology": "TypeScript",
            "markdown": "### Problem\nDeclaration Merging\n\n### Solution\nUse declaration merging to combine multiple declarations into a single entity.\n\n```typescript\ninterface User {\n  name: string;\n}\n\ninterface User {\n  age: number;\n}\n\nconst user: User = {\n  name: \"John\",\n  age: 25\n};\n```"
        },
        {
            "id": "ts_problem20",
            "title": "Module Augmentation",
            "technology": "TypeScript",
            "markdown": "### Problem\nModule Augmentation\n\n### Solution\nUse module augmentation to add new members to existing modules.\n\n```typescript\ndeclare module \"express\" {\n  interface Request {\n    user?: User;\n  }\n}\n```"
        },
        {
            "id": "ts_problem21",
            "title": "Mapped Types",
            "technology": "TypeScript",
            "markdown": "### Problem\nMapped Types\n\n### Solution\nUse mapped types to create new types based on existing ones.\n\n```typescript\ntype Readonly<T> = {\n  readonly [P in keyof T]: T[P];\n};\n\ninterface User {\n  name: string;\n  age: number;\n}\n\nlet user: Readonly<User> = {\n  name: \"John\",\n  age: 25\n};\n```"
        },
        {
            "id": "ts_problem22",
            "title": "Conditional Types",
            "technology": "TypeScript",
            "markdown": "### Problem\nConditional Types\n\n### Solution\nUse conditional types to create types based on conditions.\n\n```typescript\ntype IsString<T> = T extends string ? true : false;\n\nlet result: IsString<string>; // true\nlet result2: IsString<number>; // false\n```"
        },
        {
            "id": "ts_problem23",
            "title": "Type Aliases",
            "technology": "TypeScript",
            "markdown": "### Problem\nType Aliases\n\n### Solution\nUse type aliases to create a new name for an existing type.\n\n```typescript\ntype Point = {\n  x: number;\n  y: number;\n};\n\nlet point: Point = {\n  x: 10,\n  y: 20\n};\n```"
        },
        {
            "id": "ts_problem24",
            "title": "Index Signatures",
            "technology": "TypeScript",
            "markdown": "### Problem\nIndex Signatures\n\n### Solution\nUse index signatures to describe types that have dynamic property names.\n\n```typescript\ninterface StringArray {\n  [index: number]: string;\n}\n\nlet myArray: StringArray;\nmyArray = [\"Bob\", \"Fred\"];\n```"
        },
        {
            "id": "ts_problem25",
            "title": "Type Compatibility",
            "technology": "TypeScript",
            "markdown": "### Problem\nType Compatibility\n\n### Solution\nUnderstand that TypeScript's type system is structurally typed.\n\n```typescript\ninterface Point {\n  x: number;\n  y: number;\n}\n\ninterface Point3D extends Point {\n  z: number;\n}\n\nlet point2d: Point;\nlet point3d: Point3D = { x: 1, y: 2, z: 3 };\n\npoint2d = point3d; // Okay\n```"
        }]);
};