import React from 'react';

export const HeaderVideo: React.FC = () => {

    return (<React.Fragment>
        <div className={"video-container"}>
            <iframe src="https://www.youtube.com/embed/jz3WjTCgV-c?si=OI1us57sAaI3aZXQ"
                    title="YouTube video player" frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
        </div>
    </React.Fragment>);
}