import React from 'react';
import {Box, Container, Grid, Link, Stack, Typography, useMediaQuery} from '@mui/material';
import {config} from "../config";

const Footer: React.FC = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    return (
        <Box className="footer" component="footer" sx={{py: 4, backgroundColor: '#506c8173'}}>
            <Container maxWidth={(isSmallScreen) ? 'md' : 'lg'}>
                <Stack spacing={2} marginTop={2}>
                    <Typography variant="h5" component={'h2'} align="left">
                        {config().footer.title}
                    </Typography>
                    <Container>
                        <Grid container spacing={2} xs={2}>
                            {
                                config().footer.links.map((link, index) => (
                                   <React.Fragment>
                                       <Grid item xs={12} md={6}>
                                           {link.label}
                                       </Grid>
                                       <Grid item xs={12} md={6}>
                                           <Link href={link.url} target="_blank" rel="noopener">
                                               {link.link_label}
                                           </Link>
                                       </Grid>
                                   </React.Fragment>
                                ))
                            }
                        </Grid>
                    </Container>
                </Stack>
            </Container>
        </Box>
    );
};

export default Footer;
